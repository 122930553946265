<template>
  <div class="schedule-wrapper">
    <div class="schedule">
      <van-sticky>
      <div class="schedule-header van-hairline--bottom">
        <div @click.stop="showPicker = true" class="schedule-header-hd">{{columns[pickerIndex]}}</div>
        <div class="schedule-header-bd" @click="onCalendarOpen">{{ active.date }}</div>
        <div class="schedule-header-ft van-ellipsis" @click="showStudio = true">
          <!-- <slot name="right"><van-icon class="iconfont" class-prefix="icon" name="CalendarOutline" /></slot> -->
          {{studioValue.filter_name}}
        </div>
      </div>
      <div>
        <van-tabs class="invited-van-tabs" @click="handleTabs" v-model="activeIndex">
          <van-tab :dot='item.arr.length>0' v-for='(item,index) in list' :key='index'>
            <template #title>
              <div class='invited-van-tab'>
                <div v-if="dayjs(item.date).format('d') == 1">周一</div>
                <div v-if="dayjs(item.date).format('d') == 2">周二</div>
                <div v-if="dayjs(item.date).format('d') == 3">周三</div>
                <div v-if="dayjs(item.date).format('d') == 4">周四</div>
                <div v-if="dayjs(item.date).format('d') == 5">周五</div>
                <div v-if="dayjs(item.date).format('d') == 6">周六</div>
                <div v-if="dayjs(item.date).format('d') == 0">周日</div>
                <div class="invited-van-tab-date">{{dayjs(item.date).format("MM/DD")}}</div>
              </div>
            </template>
          </van-tab>
        </van-tabs>
      </div>
      </van-sticky>
      <slot />
    </div>
    <van-calendar v-model="show" :default-date="defaultDate" :formatter='formatter' :color="globalData.theme.color" :min-date="minDate" :max-date="maxDate" :show-confirm="false" @select="onSelect"/>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
    <van-popup v-model="showStudio" position="bottom">
        <van-picker
            show-toolbar
            value-key="filter_name"
            :columns="studios"
            @confirm="onConfirmStudio"
            @cancel="showStudio = false"
        />
    </van-popup>
  </div>
</template>

<script>
import { Calendar, Loading, Tab, Tabs, Sticky, Popup, Picker} from 'vant'
import dayjs from 'dayjs'
export default {
  name: 'schedule',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Calendar.name]: Calendar,
    [Loading.name]: Loading,
    [Sticky.name]: Sticky,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
  },
  model: {
    prop: 'active',
    event: 'change'
  },
  props:['active','invitedList'],
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      show: false,
      showStudio: false,
      showPicker: false,
      classList: [],
      courses: [],
      dotList:[],
      defaultDate: dayjs().toDate(),
      minDate: dayjs(dayjs().subtract(2, 'month')).startOf('month').toDate(),
      maxDate:dayjs(dayjs().add(2, 'month')).endOf('month').toDate(),
      activeIndex:0,
      columns: ['我的','我下属的'],
      pickerIndex:0,
      studios:[],
      studio_id:'',
      studioValue:{}
    }
  },
  computed: {
    globalData() {
      return this.$store.getters.globalData
    }
  },
  created(){
    if(localStorage.getItem("is_sub")){
        this.pickerIndex = localStorage.getItem("is_sub")
    }
    this.getStudio()
    this.list = this.getDiffDate(dayjs().startOf('month').format('YYYY-MM-DD'),dayjs().endOf('month').format('YYYY-MM-DD'))
  },
  methods: {
    dayjs,
    getList(){
      let params = {
        start_date:dayjs(this.minDate).format('YYYY-MM-DD'),
        end_date:dayjs(this.maxDate).format('YYYY-MM-DD'),
        is_sub:this.pickerIndex,
        studio_id:this.studio_id
      }
      this.$api.crm_employee_invited_schedule(params)
      .then(res=>{
        this.dotList = res.data
      })
    },
    getStudio(){
        this.$api.crm_filter_stduio()
        .then(res=>{
            this.studios = [...[{filter_name:"全部校区",studio_id: ''}],...res.data]
            this.studioValue = {filter_name:"全部校区",studio_id: ''}
            this.studio_id = ''
            this.$emit('refreshStudio', this.studio_id)
            this.getList()
        })
    },
    async onList(start_date,end_date) {
      this.$emit('refresh', true)
      let obj = {
          start_date,
          end_date
      }
      let res = await this.$api.crm_employee_invited(obj)
      this.$emit('refresh', false)
      return res.data
    },
    handleTabs() {
      let item = this.list[this.activeIndex]
      this.$emit('change', item)
    },
    async onSelect(date) {
      this.show = false
      let value = dayjs(date).format('YYYY-MM-DD')
      this.list = this.getDiffDate(dayjs(value).startOf('month').format('YYYY-MM-DD'),dayjs(value).endOf('month').format('YYYY-MM-DD'))
      let invitedList = await this.onList(dayjs(value).startOf('month').format('YYYY-MM-DD'),dayjs(value).endOf('month').format('YYYY-MM-DD'))
      await this.list.forEach(item=>{
        item.arr = invitedList[item.date]?invitedList[item.date]:[]
      })
      let obj = {}
      await this.list.forEach((item,index)=>{
        if(item.date == value){
          obj = item
          this.activeIndex = index
        }
      })
      this.$emit('change', obj)
    },
    onCalendarOpen() {
      this.show = true
    },
    formatter(day) {
      let item = dayjs(day.date).format('YYYY-MM-DD')
      if(day.type != "disabled"){
        day.bottomInfo = this.dotList[item]?'・':''
      }
      return day;
    },
    getDiffDate(start, end) {
      var startTime = this.getDate(start);
      var endTime = this.getDate(end);
      var dateArr = [];
      while ((endTime.getTime() - startTime.getTime()) >= 0) {
          var year = startTime.getFullYear();
          if(startTime.getMonth() == 9){
            var month = startTime.getMonth().toString().length === 1 ? (parseInt(startTime.getMonth().toString(),10) + 1) : (startTime.getMonth() + 1);
          }else{
            var month = startTime.getMonth().toString().length === 1 ? "0" + (parseInt(startTime.getMonth().toString(),10) + 1) : (startTime.getMonth() + 1);
          }
          var day = startTime.getDate().toString().length === 1 ? "0" + startTime.getDate() : startTime.getDate();
          dateArr.push({date:year + "-" + month + "-" + day,arr:[]});
          startTime.setDate(startTime.getDate() + 1);
      }
      return dateArr;
    },
    getDate (datestr) {
      var temp = datestr.split("-");
      if (temp[1] === '01') {
          temp[0] = parseInt(temp[0],10) - 1;
          temp[1] = '12';
      } else {
          temp[1] = parseInt(temp[1],10) - 1;
      }
      var date = new Date(temp[0], temp[1], temp[2]);
      return date;
    },
    onConfirm(val,index){
      localStorage.setItem('is_sub', index)
      if(this.pickerIndex != index){
        this.pickerIndex = index
        this.$emit('toRefresh', index)
        this.getList()
      }
      this.showPicker = false
    },
    onConfirmStudio(value){
        this.studioValue = value
        this.studio_id = value.studio_id
        this.$emit('refreshStudio', value.studio_id)
        this.getList()
        this.showStudio = false
    },
  },
  watch: {
    invitedList(newValue, oldValue) {
      let num = 0
      this.list.forEach((item,index)=>{
        if(item.date === dayjs().format('YYYY-MM-DD')){
          this.activeIndex  = index
          num = index
        }
        item.arr = this.invitedList[item.date]?this.invitedList[item.date]:[]
      })
      let item = this.list[num]
      this.$emit('change', item)
    }
  },
}
</script>
<style lang="less" scoped>

  .schedule{
    &-header{
      display: flex;
      align-items: center;
      justify-items: flex-start;
      padding:0 15px;
      background: #fff;
      &-bd{
        flex: 1;
        height: 44px;
        font-weight: 500;
        line-height: 44px;
        text-align: center;
        font-size: 14px;
      }
      &-hd{
        width: 120px;
      }
      &-ft{
        width: 120px;
        text-align: right;
      }

    }
    &-weekdays{
      display: flex;
    }
    &-weekday{
      flex: 1;
      width: 14.2%;
      font-size: 12px;
      text-align: center;
      &-hd{
        position: relative;
        line-height: 30px;
      }
      &-activebg{
        background: #00DE93;
        color: #fff;
      }
      &-bd{
        padding: 0 0 10px;
        &-txt{
          width: 25px;
          height: 25px;
          line-height: 25px;
          text-align: center;
          border-radius: 50%;
          position: relative;
          margin: 0 auto;
          &-dot{
            position: absolute;
            bottom: -6px;
            left: 50%;
            margin-left: -2px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #000;
          }
          &-dotbg{
            position: absolute;
            bottom: -6px;
            left: 50%;
            margin-left: -2px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #00DE93;
          }
        }
      }
    }
    .active{
      // background: #00DE93;
      color: #00DE93;
    }
  }

  .date-dot{
    position: absolute;
    top: 4px;
    right: 4px;
    width: 8px;
    height: 8px;
    background-color: #ee0a24;
    border-radius: 100%;
  }

   .no-data{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-sizing: border-box;
    min-height: 220px;
    text-align: center;
    &-image{
      width: 96px;
      height: 96px;
      background: #fff;
      margin-bottom: 10px;
    }

  }
  .invited-van-tab{
    height: 40px;
    div{
      text-align: center;
    }
    .invited-van-tab-date{
      font-size: 12px;
    }
  }
</style>
<style lang="less">
  .invited-van-tabs{
    .van-info{
      top:4px
    }
  }
  .schedule-wrapper .van-calendar__bottom-info{
    font-size: 32px;
    color: #ee0a24;
  }
</style>

